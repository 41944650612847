<template>
  <section fluid>
    <v-breadcrumbs>
      <template v-for="(item, index) in breadcrumbs">
        <v-breadcrumbs-item
          :key="index"
          :href="item.href"
          :disabled="item.disabled"
          v-if="item.show"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider v-if="index < breadcrumbs.length - 1">
          /
        </v-breadcrumbs-divider>
      </template>
    </v-breadcrumbs>
    <v-row>
      <v-col cols="12" md="6" class="d-flex justify-center justify-md-start">
      </v-col>

      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center flex-column flex-sm-row justify-sm-space-around justify-md-end"
      >
        <div
          class="d-flex flex-column text-center mb-3 mb-sm-0 secondary--text mr-md-6"
          v-if="false"
        >
          <span class="text-h4" v-text="`${tiempo} días`" />
          <span v-text="'Tiempo sancionado'" />
        </div>
      </v-col>
    </v-row>
    
    <v-row class="ma-1">
      <v-btn color="secondary" @click="modalSancion = true">Agregar sanción</v-btn>
    </v-row>

    <data-table-component
      :headers="HEADERS_CONST"
      :items="sanciones"
      :tiene_paginacion="false"
    >
      <!-- Slot fecha inicio -->
      <template v-slot:[`item.fecha_inicio`]="{ item }">
        {{ moment(item.fecha_inicio).format('DD/MM/YYYY') || '-' }}
      </template>
      <template v-slot:[`item.fecha_fin`]="{ item }">
        {{ moment(item.fecha_fin).format('DD/MM/YYYY') || '-' }}
      </template>

      <!-- Slot estado -->
      <!-- <template v-slot:[`item.estado`]="{ item }">
        <v-chip
          label
          :color="
            [1].includes(item.id_estado_sancion)
              ? 'success'
              : [2].includes(item.id_estado_sancion)
              ? 'warning'
              : 'info'
          "
          text-color="white"
          v-text="item.estado_sancion"
        />
      </template> -->

      <!-- Slot acciones -->
      <!-- <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="SeeDetailFtn(item.id_sancion)"
            >
              <v-icon v-text="'mdi-eye'" />
            </v-btn>
          </template>
          <span v-text="'Ver detalle de la sanción'" />
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              v-if="
                [2].includes(item.id_estado_sancion) &&
                haveRoles(['ROLE_DIRECCION_COMPRAS'])
              "
              @click="cambiarEstadoSancion(item, 1)"
            >
              <v-icon v-text="'mdi-play'" />
            </v-btn>
          </template>
          <span v-text="'Reactivar sanción'" />
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              v-if="
                [1].includes(item.id_estado_sancion) &&
                haveRoles(['ROLE_DIRECCION_COMPRAS'])
              "
              @click="cambiarEstadoSancion(item, 2)"
            >
              <v-icon v-text="'mdi-pause'" />
            </v-btn>
          </template>
          <span v-text="'Enviar a revisión'" />
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              v-if="
                [1, 2].includes(item.id_estado_sancion) &&
                haveRoles(['ROLE_DIRECCION_COMPRAS'])
              "
              @click="cambiarEstadoSancion(item, 3)"
            >
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </template>
          <span v-text="'Anular sanción'" />
        </v-tooltip>
      </template> -->
    </data-table-component>

    <!-- Botón de regresar -->
    <v-row>
      <v-col cols="12">
        <v-btn class="mt-5" color="primary" outlined @click="regresar">
          Regresar
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="modal_sancion_prov_st" persistent max-width="900px">
      <v-card class="pa-4">
        <v-row>
          <!-- corner button -->
          <v-col cols="1">
            <v-btn
              :class="$vuetify.breakpoint.lgAndUp ? 'closeBtn' : 'ma-0'"
              @click="close"
              icon
              large
            >
              <v-icon v-text="'mdi-close'" color="secondary" />
            </v-btn>
          </v-col>

          <!-- content -->
          <v-col cols="11" class="mt-4">
            <v-row class="pt-1">
              <v-col cols="12" md="6" class="d-flex align-center pl-0">
                <span
                  class="text-h5 secondary--text"
                  v-text="sancion?.codigo"
                />
              </v-col>

              <v-col cols="12" md="6" class="d-flex justify-end">
                <v-btn
                  v-if="false"
                  class="red--text text-no-style"
                  outlined
                  v-text="'Cancelar sanción'"
                />
              </v-col>
            </v-row>

            <v-row class="d-flex flex-column">
              <div class="d-flex flex-column mb-2">
                <span class="text-caption" v-text="'Fecha de sanción'" />
                <span
                  v-text="moment(sancion?.fecha_inicio).format('DD/MM/YYYY')"
                />
              </div>

              <div class="d-flex flex-column my-2">
                <span class="text-caption" v-text="'Sanción aplicada'" />
                <span v-text="sancion?.descripcion_sancion" />
              </div>

              <div class="d-flex flex-column my-2">
                <span class="text-caption" v-text="'Motivo de sanción'" />
                <p v-text="sancion?.observaciones" />
              </div>

              <div class="d-flex flex-column my-2">
                <span class="text-caption" v-text="'Tiempo sancionado'" />
                <span v-text="`${sancion?.tiempo} días`" />
              </div>
            </v-row>

            <!-- button to close -->
            <v-row class="mb-3 mt-12">
              <v-btn
                @click="close"
                class="secondary--text text-capitalize"
                text
                v-text="'volver'"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalSancion" :max-width="1200" @click:outside="cerrar">
      <v-card class="py-6" style="overflow: hidden">
        <section class="pt-0">
          <v-card-title class="text-h5 secondary--text">Sanciones</v-card-title>
          <v-card-text>
            <v-row>
              <!-- <v-col cols="12" sm="12" md="3">
                <p class="font-weight-medium primary--text mb-0">
                  Nombre del proveedor:
                </p>
                <p class="text-subtitle-1 font-weight-bold primary--text">
                  {{ provider.nombre }}
                </p>
                <p class="font-weight-medium primary--text mb-0">
                  Nombre comercial:
                </p>
                <p class="text-subtitle-1 font-weight-bold primary--text">
                  {{ provider.nombre_comercial }}
                </p>
              </v-col> -->
              <v-col cols="12" sm="12" md="9">
                <section>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        ref="select_desc"
                        class="custom-select"
                        label="Sanción a aplicar *"
                        outlined
                        
                        clearable
                        :value="newSancion.id_descripcion_sancion"
                        item-value="id"
                        return-object
                        :items="tipos_sanciones"
                        @blur="$v.newSancion.id_descripcion_sancion.$touch()"
                        @change="setYearSancion"
                        @click:clear="newSancio.id_descripcion_sancion = null"
                      >
                        <template #item="{ item, on, attrs }">
                          <div
                            class="d-flex flex-column"
                            style="width: -webkit-fill-available"
                          >
                            <div class="cerrado">
                              <p class="mb-0 mx-2">{{ item.nombre }}</p>
                            </div>
                            <v-list-item
                              v-for="desc in item.descripciones"
                              :key="desc.id"
                              v-on="on"
                              v-bind="attrs"
                              class="mx-4"
                              color="blueGrayMinsal"
                              :input-value="
                                desc.id === newSancion?.id_descripcion_sancion
                              "
                            >
                              <v-list-item-title
                                @click="setDescripcionSancion(desc.id)"
                                >{{ desc.nombre }}</v-list-item-title
                              >
                            </v-list-item>
                          </div>
                        </template>
                        <template #selection="{ item }">
                          {{
                            item.descripciones.find(
                              (target) =>
                                target.id === newSancion?.id_descripcion_sancion
                            )?.nombre
                          }}
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        type="number"
                        label="Años por sanción *"
                        outlined
                        min="1"
                        max="5"
                        @keyup="validYears"
                        v-model="selectedYear"
                      />
                    </v-col>
                    <v-col cols="4">
                      <vc-date-picker
                        mode="dateTime"
                        locale="es"
                        :popover="config_calendar"
                        :min-date="fecha_actual"
                        v-model="newSancion.fecha_inicio"
                      >
                        <template v-slot="{ inputEvents }">
                          <v-text-field
                            class="border focus:outline-none focus:border-blue-300"
                            label="Fecha y hora de inicio *"
                            readonly
                            
                            :value="formattedFechaInicio"
                            prepend-inner-icon="mdi-calendar"
                            v-on="inputEvents"
                            outlined
                            :error-messages="fechaInicioErrors"
                            @blur="$v.newSancion.fecha_inicio.$touch()"
                          />
                        </template>
                      </vc-date-picker>
                    </v-col>
                    <v-col cols="4">
                      <vc-date-picker
                        mode="dateTime"
                        locale="es"
                        :popover="config_calendar"
                        :min-date="newSancion.fecha_inicio"
                        v-model="newSancion.fecha_fin"
                      >
                        <template v-slot="{ inputEvents }">
                          <v-text-field
                            class="border focus:outline-none focus:border-blue-300"
                            label="Fecha y hora de fin *"
                            disabled
                            prepend-inner-icon="mdi-calendar"
                            v-on="inputEvents"
                            outlined
                            :value="formattedFechaFin"
                            :error-messages="fechaFinErrors"
                            @blur="$v.newSancion.fecha_fin.$touch()"
                          />
                        </template>
                      </vc-date-picker>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="newSancion.observaciones"
                        :error-messages="observacionesErrors"
                        @input="$v.newSancion.observaciones.$touch()"
                        label="Observaciones *"
                        
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        outlined
                        label="Acta de no cumplimiento *"
                        prepend-icon=""
                        append-icon="mdi-paperclip"
                        accept=".pdf"
                        
                        v-model="newSancion.documento"
                        :error-messages="documentoErrors"
                        @blur="$v.newSancion.documento.$touch()"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row class="mx-1">
                    <v-btn
                      outlined
                      color="secondary"
                      class="mr-4"
                      @click="cerrar"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="secondary"
                      class="mr-4"
                      @click="guardarSancion()"
                    >
                      Aplicar
                    </v-btn>
                  </v-row>
                </section>
              </v-col>
            </v-row>
          </v-card-text>
        </section>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { required, helpers } from 'vuelidate/lib/validators'
import moment from 'moment'
import DataTableComponent from '../../components/DataTableComponent.vue'

const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);

export default {
  name: 'contratosProveedor',
  components: {
    DataTableComponent
  },
  data () {
    return {
      HEADERS_CONST: [
        // {
        //   align: 'center',
        //   text: 'Código',
        //   value: 'codigo'
        // },
        // {
        //   align: 'center',
        //   text: 'Origen',
        //   value: 'numero_orden'
        // },
        {
          align: 'center',
          text: 'Fecha de sanción',
          value: 'fecha_inicio'
        },
        {
          align: 'center',
          text: 'Finalización de sanción',
          value: 'fecha_fin'
        },
        {
          align: 'center',
          text: 'Nombre institución',
          value: 'nombre_institucion'
        },
        {
          align: 'center',
          text: 'Motivo de sanción',
          value: 'detalle_sancion'
        },
        // {
        //   align: 'center',
        //   text: 'Acciones',
        //   value: 'acciones'
        // }
      ],
      loading: false,
      sanciones: [],
      filters: {
        page: 1,
        per_page: 10,
        total_rows: null,
        pagination: true
      },
      tiempo: null,
      modal_sancion_prov_st: false,
      id_sancion: null,
      sancion: null,
      modalSancion: false,
      tipos_sanciones: [],
      newSancion: {
        id_descripcion_sancion: null,
        id_model: null,
        documento: null,
        observaciones: null,
        fecha_inicio: null,
        fecha_fin: null,
      },
      selectedYear: 0,
      sancionSelected: {},
      config_calendar: {
        visibility: "click",
      },
      fecha_actual : moment().format("YYYY/MM/DD hh:mm"),
    }
  },
  validations: {
    newSancion: {
      id_descripcion_sancion: { required },
      documento: { required },
      observaciones: { required, txtArea },
      fecha_inicio: { required },
      fecha_fin: { required },
    },
  },
  computed: {
    ...mapState('proveedores', ['provider']),
    ...mapState('agregarInsumo', ['selectedProvider']),
    ...mapState('sancionesStore', ['detail_prov_st']),
    breadcrumbs () {
      return [
        {
          show: this.haveRole('ROLE_SUPERVISOR') ? true : false,
          text: 'RUPES',
          disabled: false,
          href: `/rupes`
        },
        {
          show: true,
          text: this.provider?.nombre,
          disabled: false,
          href: `/proveedor/${this.$route.params.id_proveedor}`
        },
        {
          show: true,
          text: 'Sanciones',
          disabled: true
        }
      ]
    },
    porPaginaModel: {
      get () {
        return this.filters.per_page
      },
      set (value) {
        this.setFiltroPorPagina(value)
      }
    },
    paginaModel: {
      get () {
        return this.filters.page
      },
      set (value) {
        this.setFiltroPage(value)
      }
    },
    descripcionSancionErrors() {
      const errors = [];
      if (!this.$v.newSancion.id_descripcion_sancion.$dirty) return errors;
      !this.$v.newSancion.id_descripcion_sancion.required &&
        errors.push("Sanción a aplicar es obligatorio");
      return errors;
    },
    fechaInicioErrors() {
      const errors = [];
      if (!this.$v.newSancion.fecha_inicio.$dirty) return errors;
      !this.$v.newSancion.fecha_inicio.required &&
        errors.push("Fecha y hora de inicio es obligatorio");
      return errors;
    },
    fechaFinErrors() {
      const errors = [];
      if (!this.$v.newSancion.fecha_fin.$dirty) return errors;
      !this.$v.newSancion.fecha_fin.required &&
        errors.push("Fecha y hora de fin es obligatorio");
      return errors;
    },
    observacionesErrors() {
      const errors = [];
      if (!this.$v.newSancion.observaciones.$dirty) return errors;
      !this.$v.newSancion.observaciones.required &&
        errors.push("Observaciones es obligatorio");
      !this.$v.newSancion.observaciones.txtArea &&
        errors.push("Caracteres especiales no permitidos");
      return errors;
    },
    documentoErrors() {
      const errors = [];
      if (!this.$v.newSancion.documento.$dirty) return errors;
      !this.$v.newSancion.documento.required &&
        errors.push("Acta de no cumplimiento es obligatorio");
      return errors;
    },
    formattedFechaInicio() {
      return this.newSancion.fecha_inicio
        ? moment(this.newSancion.fecha_inicio).format("DD/MM/YYYY hh:mm A")
        : "";
    },
    formattedFechaFin() {
      return this.newSancion.fecha_fin
        ? moment(this.newSancion.fecha_fin).format("DD/MM/YYYY hh:mm A")
        : "";
    },
  },
  methods: {
    ...mapMutations('sancionesStore', [
      'resetListSancionProvMt',
      'resetListSancionProvPagMt',
      'SetIdSancionMt',
      'SetModalSancionProvMt'
    ]),
    ...mapMutations('agregarInsumo', ['setCurrentProvider', 'setProviderInfo']),
    ...mapMutations('sancionesStore', ['resetDetailProvMt']),
    ...mapActions('proveedores', ['getProvider']),
    ...mapActions('sancionesStore', ['FetchDetailSancionAc']),
    // async getSanciones () {

    //   const response = await this.services.Sanciones.reqGetListSancProv(
    //     this.provider.id,
    //     this.filters
    //   )
    //   this.sanciones = response.data
    //   this.filters.page = Number(response?.headers.page)
    //   this.filters.per_page = Number(response?.headers.per_page)
    //   this.filters.total_rows = Number(response?.headers.total_rows)

    // },
    async getSanciones() {
      const response = await this.services.Sanciones.reqGetDetailProv(this.provider.id,this.filters);
      this.sanciones = response.data;
    },
    paginar (filtros) {
      const { cantidad_por_pagina, pagina } = filtros
      this.filters.page = pagina
      this.filters.per_page = cantidad_por_pagina
      this.getSanciones()
    },
    async SeeDetailFtn (id_sancion_param) {
      this.id_sancion = id_sancion_param
      this.modal_sancion_prov_st = true
      const response = await this.services.Sanciones.reqGetDetailSanc(
        this.id_sancion
      )
      this.sancion = response.data
    },
    close () {
      this.modal_sancion_prov_st = false
    },
    async detailSancionProveedor () {
      const response = await this.services.Sanciones.reqGetDetailProv(
        this.provider.id
      )
      this.tiempo = response?.data?.tiempo
    },
    InitFtn () {
      this.setCurrentProvider(this.provider.id)
      this.setProviderInfo(this.detail_prov_st)
    },
    async cambiarEstadoSancion (item, id_estado_sancion) {
      const response = await this.services.Sanciones.setEstadoSancion(
        item.id_sancion,
        {
          id_estado_sancion
        }
      )

      if (response.status == 204) {
        this.temporalAlert({
          show: true,
          type: 'success',
          message: 'Se ha cambiado el estado de la sanción para este proveedor'
        })
        this.getSanciones()
      }
    },
    regresar () {
      this.$router.push({
        name: 'proveedor',
        params: { id_proveedor: this.$route.params.id_proveedor }
      })
    },
    validYears() {
      if (Number(this.selectedYear) < 1 || Number(this.selectedYear) > 5)
        this.selectedYear = "";
    },
    formClean() {
      Object.keys(this.newSancion).forEach((key) => {
        this.newSancion[key] = null;
      });
      this.$v.$reset();
    },
    setFechaFin() {
      this.newSancion.fecha_fin = moment(this.newSancion.fecha_inicio).add(
        this.selectedYear,
        "Y"
      );
    },
    setDescripcionSancion(id) {
      this.newSancion.id_descripcion_sancion = id;
    },
    setYearSancion(data) {
      this.selectedYear = data?.anios;
    },
    async getTiposSanciones() {
      const { status, data } =
        await this.services.ContratoService.getTiposSanciones();
      if (status === 200) {
        this.tipos_sanciones = data;
      }
    },
    async guardarSancion() {
      this.$v.$touch();
      if (!this.$v.$invalid) {


        this.newSancion.fecha_inicio = moment(
          this.newSancion.fecha_inicio
        ).format("YYYY/MM/DD hh:mm:ss");
        this.newSancion.fecha_fin = moment(this.newSancion.fecha_fin).format(
          "YYYY/MM/DD hh:mm:ss"
        );
        let formData = new FormData();
        Object.keys(this.newSancion).forEach((key) => {
          if (this.newSancion[key]) formData.append(key, this.newSancion[key]);
        });
        formData.append("years", this.selectedYear);

        const response = await this.services.ContratoService.postSanciones(
          this.provider.id,
          formData
        );
        if (response.status === 201) {
          this.temporalAlert({
            show: true,
            message: "Sanción registrada",
            type: "success",
          });
          const { status } = await this.services.Sanciones.setEstadoSancion(
            response.data.id,
            {
              id_estado_sancion: 1
            }
          );
          if (status === 204) {
            this.temporalAlert({
              show: true,
              type: "success",
              message: "El reporte de sanción fue aprobada",
            });
            this.getSanciones()
            this.cerrar()
          }
          }
      }
    },
    cerrar(){
      this.modalSancion = false
      this.formClean()
    }
  },
  watch: {
    detail_prov_st () {
      this.InitFtn()
    },
    "newSancion.fecha_inicio"() {
      this.setFechaFin();
    },
    sancionSelected() {
      if (Object.keys(this.sancionSelected).length > 0) {
        Object.keys(this.sancionSelected).forEach((key) => {
          this.newSancion[key] = this.sancionSelected[key];
        });

        let start = moment(this.sancionSelected.fecha_inicio);
        let end = moment(this.sancionSelected.fecha_fin);
        let years = end.diff(start, "years");
        this.setYearSancion({ anios: years });
      } else {
        this.setYearSancion({ anios: 0 });
      }
    },
  },
  async created () {
    await this.getProvider(Number(this.$route.params.id_proveedor))
    await this.getSanciones()
    this.InitFtn()
    await this.detailSancionProveedor()
    this.getTiposSanciones();
  }
}
</script>

<style>
.closeBtn {
  margin: 20px 30px;
}

/*  media query para modificar margin de button corner */
@media (max-width: 1700px) {
  .closeBtn {
    margin: 20px 20px;
  }
}
</style>
