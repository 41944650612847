<template>
  <section fluid>
    <v-breadcrumbs>
      <template v-for="(item, index) in breadcrumbs">
        <v-breadcrumbs-item
          :key="index"
          :href="item.href"
          :disabled="item.disabled"
          v-if="item.show"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider v-if="index < breadcrumbs.length - 1">
          /
        </v-breadcrumbs-divider>
      </template>
    </v-breadcrumbs>

    <data-table-component
      :headers="headers"
      :show_loading="loading"
      :items="contratos"
      v-models:select="porPaginaModel"
      v-models:pagina="paginaModel"
      @paginar="paginar"
      :total_registros="filters.total_rows"
    >
      <template #[`item.monto_adjudicado`]="{ item }">
        {{
          Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(item.monto_adjudicado)
        }}
      </template>
      <template #[`item.fecha_hora_contratacion`]="{ item }">
        {{ moment(item.fecha_hora_contratacion).format('DD/MM/YYYY hh:mm A') }}
      </template>
      <template #[`item.accion`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :to="{
                name: 'liquidacion-orden-compra',
                params: { idContrato: item.id_contrato, onlyView: 1 }
              }"
            >
              <v-icon> mdi-eye </v-icon>
            </v-btn>
          </template>
          <span> Ver </span>
        </v-tooltip>
      </template>
    </data-table-component>

    <!-- Botón de regresar -->
    <v-row>
      <v-col cols="12">
        <v-btn class="mt-5" color="primary" outlined @click="regresar">
          Regresar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DataTableComponent from '../../components/DataTableComponent.vue'
export default {
  name: 'contratosProveedor',
  components: {
    DataTableComponent
  },
  data () {
    return {
      headers: [
        {
          text: 'Codigo contrato',
          value: 'numero_contrato'
        },
        {
          text: 'Nombre contrato',
          value: 'nombre_contrato'
        },
        {
          text: 'Monto adjudicado ($)',
          value: 'monto_adjudicado',
          align: 'center'
        },
        {
          text: 'Fecha y hora de contratación',
          value: 'fecha_hora_contratacion'
        },
        {
          text: 'Plazo contractual',
          value: 'plazo_contractual',
          align: 'center'
        },
        {
          text: 'Acción',
          value: 'accion'
        }
      ],
      loading: false,
      contratos: [],
      filters: {
        page: 1,
        per_page: 10,
        total_rows: null,
        pagination: true
      }
    }
  },
  computed: {
    ...mapState('proveedores', ['provider']),
    breadcrumbs () {
      return [
        {
          show: !this.haveRole('ROLE_SUPERVISOR'),
          text: 'RUPES',
          disabled: false,
          href: `/rupes`
        },
        {
          show: true,
          text: this.provider?.nombre,
          disabled: false,
          href: `/proveedor/${this.$route.params.id_proveedor}`
        },
        {
          show: true,
          text: 'Contratos',
          disabled: true
        }
      ]
    },
    porPaginaModel: {
      get () {
        return this.filters.per_page
      },
      set (value) {
        this.setFiltroPorPagina(value)
      }
    },
    paginaModel: {
      get () {
        return this.filters.page
      },
      set (value) {
        this.setFiltroPage(value)
      }
    }
  },
  methods: {
    ...mapActions('proveedores', ['getProvider']),
    async getContratosProveedores () {
      this.loading = true
      const response = await this.services.Proveedores.getContratosProveedor(
        this.provider.id,
        this.filters
      )
      if (response.status === 200) {
        this.contratos = response.data.map(record => {
          return {
            numero_contrato:
              record.pcom.proc[0].subp[0].ganador[0].cont[0].numero,
            nombre_contrato:
              record.pcom.proc[0].subp[0].ganador[0].cont[0].nombre_contrato,
            monto_adjudicado:
              record.pcom.proc[0].subp[0].ganador[0].cont[0].monto_adjudicado,
            fecha_hora_contratacion:
              record.pcom.proc[0].subp[0].ganador[0].cont[0]
                .fecha_hora_contratacion,
            plazo_contractual:
              record.pcom.proc[0].subp[0].ganador[0].cont[0].plazo_contractual,
            id_contrato: record.pcom.proc[0].subp[0].ganador[0].cont[0].id
          }
        })
        this.filters.page = Number(response?.headers.page)
        this.filters.per_page = Number(response?.headers.per_page)
        this.filters.total_rows = Number(response?.headers.total_rows)
        this.loading = false
      } else {
        this.loading = false
      }
      setTimeout(() => (this.dataLoading = false), 200)
    },
    paginar (filtros) {
      const { cantidad_por_pagina, pagina } = filtros
      this.filters.page = pagina
      this.filters.per_page = cantidad_por_pagina
      this.getContratosProveedores()
    },
    regresar () {
      this.$router.push({
        name: 'proveedor',
        params: { id_proveedor: this.$route.params.id_proveedor }
      })
    }
  },
  async created () {
    await this.getProvider(Number(this.$route.params.id_proveedor))
    await this.getContratosProveedores()
  }
}
</script>
