<template>
    <section fluid>
      <v-row>
        <v-col cols="12" md="6" class="d-flex justify-center justify-md-start">
        </v-col>
  
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center flex-column flex-sm-row justify-sm-space-around justify-md-end"
        >
          <div
            class="d-flex flex-column text-center mb-3 mb-sm-0 secondary--text mr-md-6"
            v-if="false"
          >
            <span class="text-h4" v-text="`${tiempo} días`" />
            <span v-text="'Tiempo sancionado'" />
          </div>
        </v-col>
      </v-row>

      <p class="mt-5 text-h5 secondary--text"> {{ provider.nombre }}</p>

      <span class="text-h6 secondary--text my-3">Listado de sanciones</span>
      <data-table-component
        :headers="HEADERS_CONST"
        :items="sanciones"
        v-models:select="porPaginaModel"
        v-models:pagina="paginaModel"
        @paginar="paginar"
        :total_registros="filters.total_rows"
      >
        <!-- Slot fecha inicio -->
        <template v-slot:[`item.fecha_inicio`]="{ item }">
          {{ moment(item.fecha_inicio).format('DD/MM/YYYY') || '-' }}
        </template>
  
        <!-- Slot estado -->
        <template v-slot:[`item.estado`]="{ item }">
          <v-chip
            label
            :color="
              [1].includes(item.id_estado_sancion)
                ? 'success'
                : [2].includes(item.id_estado_sancion)
                ? 'warning'
                : 'info'
            "
            text-color="white"
            v-text="item.estado_sancion"
          />
        </template>
  
        <!-- Slot acciones -->
        <template v-slot:[`item.acciones`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="SeeDetailFtn(item.id_sancion)"
              >
                <v-icon v-text="'mdi-eye'" />
              </v-btn>
            </template>
            <span v-text="'Ver detalle de la sanción'" />
          </v-tooltip>
        </template>
      </data-table-component>
  
      <!-- Botón de regresar -->
      <v-row>
        <v-col cols="12">
          <v-btn class="mt-5" color="primary" outlined @click="regresar">
            Regresar
          </v-btn>
        </v-col>
      </v-row>
  
      <v-dialog v-model="modal_sancion_prov_st" persistent max-width="60vh">
        <v-card class="pa-4">
          <v-row>
            <!-- corner button -->
            <v-col cols="1">
              <v-btn
                :class="$vuetify.breakpoint.lgAndUp ? 'closeBtn' : 'ma-0'"
                @click="close"
                icon
                large
              >
                <v-icon v-text="'mdi-close'" color="secondary" />
              </v-btn>
            </v-col>
            <v-col cols="1"></v-col>
            <!-- content -->
            <v-col cols="10" class="mt-4">
              <v-row class="pt-1">
                <v-col cols="12" md="6" class="d-flex align-center pl-0">
                  <span
                    class="text-h5 secondary--text"
                    v-text="sancion?.codigo"
                  />
                </v-col>
              </v-row>
  
              <v-row class="d-flex flex-column">
                <div class="d-flex flex-column mb-2">
                  <span class="text-caption" v-text="'Fecha de sanción'" />
                  <span
                    v-text="sancion?.fecha_inicio ? moment(sancion?.fecha_inicio).format('DD/MM/YYYY') : ''"
                  />
                </div>
  
                <div class="d-flex flex-column my-2">
                  <span class="text-caption" v-text="'Sanción aplicada'" />
                  <span v-text="sancion?.descripcion_sancion" />
                </div>
  
                <div class="d-flex flex-column my-2">
                  <span class="text-caption" v-text="'Motivo de sanción'" />
                  <p v-text="sancion?.observaciones" />
                </div>
  
                <div class="d-flex flex-column my-2">
                  <span class="text-caption" v-text="'Tiempo sancionado'" />
                  <span v-text="sancion?.tiempo ? `${sancion?.tiempo} días` : ''" />
                </div>
              </v-row>
  
              <!-- button to close -->
              <v-row class="mb-3 mt-12">
                <v-btn
                  @click="close"
                  class="secondary--text text-capitalize"
                  text
                  v-text="'volver'"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </section>
  </template>
  
  <script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import InfoProductoComponent from '@/components/InfoProductoComponent.vue'
  import DataTableComponent from '../../components/DataTableComponent.vue'
  export default {
    name: 'contratosProveedor',
    components: {
      DataTableComponent
    },
    data () {
      return {
        HEADERS_CONST: [
          {
            align: 'center',
            text: 'Código',
            value: 'codigo'
          },
          {
            align: 'center',
            text: 'Origen',
            value: 'numero_orden'
          },
          {
            align: 'center',
            text: 'Fecha de sanción',
            value: 'fecha_inicio'
          },
          {
            align: 'center',
            text: 'Estado de sanción',
            value: 'estado'
          },
          {
            align: 'center',
            text: 'Motivo de sanción',
            value: 'descripcion_sancion'
          },
          {
            align: 'center',
            text: 'Tiempo (días)',
            value: 'tiempo'
          },
          {
            align: 'center',
            text: 'Acciones',
            value: 'acciones'
          }
        ],
        loading: false,
        sanciones: [],
        filters: {
          page: 1,
          per_page: 10,
          total_rows: null,
          pagination: true
        },
        tiempo: null,
        modal_sancion_prov_st: false,
        id_sancion: null,
        sancion: null
      }
    },
    computed: {
      ...mapState('proveedores', ['provider']),
      ...mapState('agregarInsumo', ['selectedProvider']),
      ...mapState('sancionesStore', ['detail_prov_st']),
      breadcrumbs () {
        return [
          {
            show: this.haveRole('ROLE_SUPERVISOR') ? true : false,
            text: 'RUPES',
            disabled: false,
            href: `/rupes`
          },
          {
            show: true,
            text: this.provider?.nombre,
            disabled: false,
            href: `/proveedor/${this.$route.params.id_proveedor}`
          },
          {
            show: true,
            text: 'Sanciones',
            disabled: true
          }
        ]
      },
      porPaginaModel: {
        get () {
          return this.filters.per_page
        },
        set (value) {
          this.setFiltroPorPagina(value)
        }
      },
      paginaModel: {
        get () {
          return this.filters.page
        },
        set (value) {
          this.setFiltroPage(value)
        }
      }
    },
    methods: {
      ...mapMutations('sancionesStore', [
        'resetListSancionProvMt',
        'resetListSancionProvPagMt',
        'SetIdSancionMt',
        'SetModalSancionProvMt'
      ]),
      ...mapMutations('agregarInsumo', ['setCurrentProvider', 'setProviderInfo']),
      ...mapMutations('sancionesStore', ['resetDetailProvMt']),
      ...mapActions('proveedores', ['getProvider']),
      ...mapActions('sancionesStore', ['FetchDetailSancionAc']),
      async getSanciones () {

        const response = await this.services.Sanciones.reqGetListSancProv(
          this.provider.id,
          this.filters
        )
        this.sanciones = response.data
        this.filters.page = Number(response?.headers.page)
        this.filters.per_page = Number(response?.headers.per_page)
        this.filters.total_rows = Number(response?.headers.total_rows)
      },
      paginar (filtros) {
        const { cantidad_por_pagina, pagina } = filtros
        this.filters.page = pagina
        this.filters.per_page = cantidad_por_pagina
        this.getSanciones()
      },
      async SeeDetailFtn (id_sancion_param) {
        this.id_sancion = id_sancion_param
        this.modal_sancion_prov_st = true

        const response = await this.services.Sanciones.reqGetDetailSanc(
          this.id_sancion
        )
        this.sancion = response.data
      },
      close () {
        this.modal_sancion_prov_st = false
      },
      async detailSancionProveedor () {
        const response = await this.services.Sanciones.reqGetDetailProv(
          this.provider.id
        )
        this.tiempo = response?.data?.tiempo
      },
      InitFtn () {
        this.setCurrentProvider(this.provider.id)
        this.setProviderInfo(this.detail_prov_st)
      },
      regresar () {
        this.$router.back()
      }
    },
    watch: {
      detail_prov_st () {
        this.InitFtn()
      }
    },
    async created () {
      await this.getProvider(Number(this.$route.params.id_proveedor))
      await this.getSanciones()
      this.InitFtn()
      await this.detailSancionProveedor()
    }
  }
  </script>
  
  <style>
  .closeBtn {
    margin: 20px 30px;
  }
  
  /*  media query para modificar margin de button corner */
  @media (max-width: 1700px) {
    .closeBtn {
      margin: 20px 20px;
    }
  }
  </style>
  